/*jshint esversion: 6 */
import React, { Component } from 'react';

class Home extends Component {
	render () {
		return(
				<div className="slide">
				    <ul id="slideshow">
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				      <li></li>
				    </ul>
					<header className="slide-header">
						<h1>Welcome!</h1>
						<h1>This is Paulo</h1>
						<h1>Cuellas' web place.</h1>
					</header>
				</div>
			);
	}
}

export default Home;


